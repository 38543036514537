@import "material-icons/iconfont/material-icons.css";
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@font-face {
  font-family: Vazir;
  src: url("./assets/fonts/Vazir.eot");
  src: url("./assets/fonts/Vazir.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Vazir.woff2") format("woff2"),
    url("./assets/fonts/Vazir.woff") format("woff"),
    url("./assets/fonts/Vazir.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: Vazir;
  src: url("./assets/fonts/Vazir-Medium.eot");
  src: url("./assets/fonts/Vazir-Medium.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Vazir-Medium.woff2") format("woff2"),
    url("./assets/fonts/Vazir-Medium.woff") format("woff"),
    url("./assets/fonts/Vazir-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: Vazir;
  src: url("./assets/fonts/Vazir-Bold.eot");
  src: url("./assets/fonts/Vazir-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Vazir-Bold.woff2") format("woff2"),
    url("./assets/fonts/Vazir-Bold.woff") format("woff"),
    url("./assets/fonts/Vazir-Bold.ttf") format("truetype");
  font-weight: bold;
}

html,
body {
  height: 100%;
  min-height: 100% !important;
}

body {
  margin: 0;
}

* {
  font-family: Vazir;
}

.mat-slide-toggle-content {
  font-family: Vazir !important;
}

.mat-select-search-input {
  font-family: Vazir !important;
}

.mat-mdc-list-item-unscoped-content {
  font-weight: bold !important;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-mdc-dialog-title {
  font-size: larger !important;
  font-weight: bold !important;
  margin-bottom: 25px !important;
}

.mat-tooltip {
  font-family: Vazir;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;

  .mat-mdc-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    display: table;
    border-collapse: collapse;
    margin: 0px;
  }

  .mat-mdc-row,
  .mat-mdc-header-row {
    display: table-row;
  }

  .mat-mdc-cell,
  .mat-mdc-header-cell {
    word-wrap: initial;
    display: table-cell;
    padding: 5px 5px;
    line-break: unset;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
  }
}

.mat-card-header {
  padding-bottom: 15px;
  font-size: x-large;
}

.snake-error {
  background-color: rgb(255, 0, 0);
  color: #ffff;
}

.snake-success {
  background-color: #4caf50;
  color: #ffff;
}

.toggle-class {
  display: flex;
  align-content: center;
  align-items: center;
}
.ql-editor {
  direction: rtl;
  text-align: right;
}
.mat-radio-button ~ .mat-radio-button {
  margin-right: 16px !important;
}

::ng-deep .mat-mdc-dialog-container {
  .mdc-dialog__title,
  .mdc-dialog__content {
    letter-spacing: normal !important;
  }
}

.mat-mdc-header-row {
  font-size: 0.8em;
  font-weight: bold;
}
